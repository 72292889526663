import { Router } from "./components/router/Router";

import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
  connectorsForWallets
} from '@rainbow-me/rainbowkit';
import { injectedWallet, rainbowWallet, walletConnectWallet, okxWallet, metaMaskWallet } from '@rainbow-me/rainbowkit/wallets';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  mainnet,
  sepolia,
  polygon
} from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

const { chains, publicClient } = configureChains(
  [polygon, mainnet, sepolia],
  [
    alchemyProvider({ apiKey: "6oOwMc5Tz68c31iPs4jL1t3IA0nJE0I4" }), //NOTE: WE WILL HAVE TO REPLACE THIS
    publicProvider()
  ]
);

const projectId = '125d3c0a70605720779010e27c4744ae'

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      metaMaskWallet({ projectId, chains }),
      rainbowWallet({ projectId, chains }),
      walletConnectWallet({ projectId, chains }),
      okxWallet({projectId, chains})
    ],
  },
]);
// ({
//   appName: 'Farcana Enigma Vault',
//   projectId: '125d3c0a70605720779010e27c4744ae', //NOTE: WE WILL HAVE TO REPLACE THIS
//   chains
// });

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
})


function App() {
  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider chains={chains}>
          <Router />
        </RainbowKitProvider> 
      </WagmiConfig>
    </>  
  );
}

export default App;
