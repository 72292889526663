import framer from "../../assets/frame-global.png";
import clip_2 from "../../assets/clip_2.svg";
import btn_bg from "../../assets/btn-bg.svg";
import youtube from "../../assets/youtube.svg";
import insta from "../../assets/insta.svg";
import discord from "../../assets/discord.svg";
import error_img from "../../assets/error-img.svg";
import { useStateContext } from "../../context/State";
import Header from "../Header";
export const ErrorPage = () => {
  const { mint, connected, allowlist, error } = useStateContext();
  return (
    <div className="flex flex-col items-center gap-10 pb-10">
    <div
className="flex justify-center flex-col mx-auto"
    >
    
      {/* header end */}
      <div className="flex flex-col justify-center">
        <span className="font-DMSans font-bold text-dark uppercase text-center">
          yikes! something went wrong
        </span>
      </div>
      <div className="relative mx-auto  flex">
        <h1 className="text-5xl xs:text-7xl relative z-10 text-dark md:text-9xl gap-x-4 uppercase flex flex-col text-center ">
          <span>error</span>
        </h1>
      </div>

      <div className="max-w-md w-full relative z-10">
        <img className=" origin-bottom w-full" src={error_img} alt="" />
      </div>
      <div className="text-center max-w-md mx-auto">
          <span className="font-DMSans font-bold text-dark uppercase">{error}</span>
        </div>
        <br></br>
        <div>
          <button
            onClick={mint}
            className="uppercase min-w-[200px] mx-auto font-DMSans rounded-md text-dark h-full text-xl font-bold flex items-center py-4 justify-center px-8 bg-yellow hover:bg-yellow-300 transition-colors relative"
          >
            <img className="absolute top-0 w-full object-contain left-0" src={btn_bg} alt="" />
            try again
          </button>
        </div>

     
    </div>
  </div>
  );
};
