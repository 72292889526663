import { useState } from "react";

export const useProgress = () => {
  const [progress, setProgress] = useState(0);
  let interval;

  const start = () => {
    setProgress(0);
    interval = setInterval(() => {
      setProgress((prev) => {
        if (prev === 99) {
          return 99;
        } else {
          return prev + 1;
        }
      });
    }, 100);
  };
  const end = () => {
    clearInterval(interval);
    setProgress(100);
  };
  return {
    progress,
    start,
    end,
  };
};
