import { Outlet } from "react-router-dom";
import { StateContextProvider, useStateContext } from "../../context/State";
import { MainPage } from "../main-page/MainPage";
import framer from "../../assets/frame-global.png";

import bg from "../../assets/new_background.png";
import Header from "../Header";
import Footer from "../Footer";
export const Layout = ({ children }) => {
  

  return (
    <StateContextProvider>
        <div className="relative min-h-[100dvh] h-fit w-full flex flex-col overflow-hidden bg-light">
        <div
          style={{
            backgroundImage: `url(${framer})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
          className={`w-full  h-full flex-1  relative flex flex-col items-center justify-between gap-10 bg-[length:0_0] md:bg-[length:max(100%,1000px)_100%]`}
        >
          {/* ----------- */}
          <div className="absolute w-screen h-full top-1/2 -translate-y-1/2">
            <img className="object-cover w-full h-full" src={bg} alt="" />
          </div>
          {/* ----------- */}
          <Header />
          <Outlet />
          <Footer />
         
        </div>
      </div>
    </StateContextProvider>
  );
};
