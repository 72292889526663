import React, {useState, useEffect} from 'react';
import { useStateContext } from '../context/State';
import Countdown from 'react-countdown';
import clip_2 from "../assets/clip_2.svg";
import { FarcanaSVG } from "../components/svg/FarcanaSVG";
import youtube from "../assets/youtube.svg";
import insta from "../assets/insta.svg";
import discord from "../assets/discord.svg";

const Header = () => {
  const {countdownTime1, countdownTime2, countdownTime3} = useStateContext();
 // Random component
const Completed = () => <span>Live !</span>;

// Renderer callback with condition
const renderer = ({ hours,days, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completed />;
  } else {
    let countdownText = "";

    if (days > 0) {
      countdownText += `${days} day${days > 1 ? 's' : ''} ${hours}:${minutes}:${seconds} `;
    } else {
      countdownText += `${hours}:${minutes}:${seconds}`;
    }


    // Render the countdown text
    return <span>{countdownText}</span>;
  }
};

    return (
           <div className="w-full">
        {/* header start */}
        <div className="max-w-4xl flex w-full relative mx-auto">
          <div className="w-28 h-16 hidden xs:flex">
            <img className="w-full h-full -scale-y-100 object-cover" src={clip_2} alt="" />
          </div>
          <div className="w-full h-16 hidden xs:flex bg-white "></div>
          <div className="w-28 h-16  hidden xs:flex">
            <img className="w-full h-full -scale-100 object-cover" src={clip_2} alt="" />
          </div>
          <div className="xs:absolute w-full h-full flex  xs:space-x-0 justify-evenly items-center p-3">
            <div className="xs:flex-row flex-col flex space-x-2 xs:space-x-0   xs:gap-5 items-center h-full">
              <div className="leading-4 xs:text-left text-center uppercase font-DMSans font-bold text-sm">
                guaranteed
                <br /> whitelist
              </div>
              <div className="h-full px-3 xs:my-0 my-2 rounded-md flex justify-center items-center bg-violet text-white uppercase gap-2">
                <span className='text-center xs:block flex'><Countdown
date={countdownTime1}
renderer={renderer}
/></span>
              </div>
            </div>
            <div className="xs:flex-row flex-col text-center  space-x-2 xs:space-x-0 flex  xs:gap-5 items-center h-full">
              <div className="leading-4 xs:text-left text-center uppercase font-DMSans font-bold text-sm">
                FCFS
                <br /> Mint
              </div>
              <div className="h-full px-3 xs:my-0 my-2 rounded-md flex justify-center items-center bg-violet text-white uppercase gap-2">
                <span className='text-center xs:block flex'><Countdown
date={countdownTime2}
renderer={renderer}
/></span>
              </div>
            </div>
            <div className=" xs:flex-row flex-col text-center  space-x-2 xs:space-x-0 flex  xs:gap-5 items-center h-full">
              <div className="leading-4  xs:text-left text-center uppercase font-DMSans font-bold text-sm">
                public
                <br /> mint
              </div>
              <div className="h-full px-3 xs:my-0 my-2 rounded-md flex justify-center items-center bg-violet text-white uppercase gap-2">
                <span className='text-center xs:block flex'><Countdown
date={countdownTime3}
renderer={renderer}
/></span>
              </div>
            </div>
          </div>
        </div>
        {/* header end */}
        {/* { ---} */}
        <div className="relative md:absolute py-4 md:py-0 md:left-10 md:top-20 lg:top-16 xl:top-10">
          <div className="max-w-xs w-fit  mx-auto">
            <FarcanaSVG />
          </div>
        </div>
        <div className="md:flex items-center gap-3 hidden absolute md:top-20 lg:top-16 xl:top-10 right-10">
          <a
            className="flex h-10 w-10 rounded-full items-center justify-center border border-muted hover:bg-gray-100 transition-colors"
            target="_blank"
            href="https://www.youtube.com/channel/UC7xXrKBeE9DKmub1zUUe6EA"
          >
            <img className="object-contain w-3/5" src={youtube} alt="" />
          </a>
          <a
            className="flex h-10 w-10 rounded-full items-center justify-center border border-muted hover:bg-gray-100 transition-colors"
            target="_blank"
            href="https://www.instagram.com/farcana_official/"
          >
            <img className="object-contain w-3/5" src={insta} alt="" />
          </a>
          <a
            className="flex h-10 w-10 rounded-full items-center justify-center border border-muted hover:bg-gray-100 transition-colors"
            target="_blank"
            href="https://discord.com/invite/farcana"
          >
            <img className="object-contain w-3/5" src={discord} alt="" />
          </a>
        </div>
        {/* { ---} */}
      </div>
    );
};

export default Header;
