import btn_bg from "../../assets/btn-bg.svg";
import one from "../../assets/1.svg";
export const SuccessPage = () => {

  const redirectToExternalLink = () => {
    // Replace 'https://example.com' with your desired external link
    const externalLink = 'https://opensea.io/collection/farcana-mars-enigma-vault';
    window.open(externalLink, '_blank');
  };


  return (
    <div className="flex flex-col items-center gap-10">
      <div className="">
        <span className="font-DMSans font-bold text-dark uppercase">
          JOIN OUR EARLY PLAYTEST NOW
        </span>
      </div>
      <div className="relative  flex">
        <h1 className="text-5xl xs:text-7xl relative z-10 text-dark md:text-8xl gap-x-4 uppercase flex flex-wrap justify-center text-center ">
          <span>Minted </span> <span>successfully</span>
        </h1>
      </div>

      <div className="max-w-xs w-full relative z-10">
        <img className="origin-bottom" src={one} alt="" />
      </div>

      <div className="w-full px-3">
        <button onClick={redirectToExternalLink} className="max-w-xs w-full md:w-fit mx-auto uppercase min-w-[200px] font-DMSans rounded-md text-dark h-full text-xl font-bold flex items-center py-4 justify-center px-8 bg-yellow hover:bg-yellow-300 transition-colors relative">
          <img className="absolute top-0 w-full object-contain left-0" src={btn_bg} alt="" />
          join
        </button>
      </div>
    </div>
  );
};
