import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Layout } from "../layout/Layout";
import ContainerPage from "../main-page/ContainerPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <ContainerPage />,
      },
      // {
      //   path: "success",
      //   element: <SuccessPage />,
      // },
      // {
      //   path: "error",
      //   element: <ErrorPage />,
      // },
    ],
  },
]);
export const Router = () => {
  return <RouterProvider router={router} />;
};
