import bg from "../../assets/global-bg.svg";
import framer from "../../assets/frame-global.png";
import box from "../../assets/box-img.svg";
import clip_2 from "../../assets/clip_2.svg";
import btn_bg from "../../assets/btn-bg.svg";

import { useStateContext } from "../../context/State";
import back_pattern from "../../assets/back_pattern.png";

export const MainPage = () => {
  const { connect, mint, connected, allowlist } = useStateContext();

  return (
    <div>
    <div className="flex flex-col px-2 items-center">
      <div className="relative py-5 md:py-10 flex">
        <h1 className="text-5xl relative z-10 text-dark xs:text-8xl md:text-9xl gap-x-4 uppercase flex flex-col text-center">
          <span>Enigma </span> <span> Vault Mint</span>
        </h1>
      </div>
      <div className="relative mb-20 mt-8 xs:mb-8">
        <div className="absolute top-10 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-3xl min-w-[460px] w-screen ">
          <img className="object-contain" src={back_pattern} alt="" />
        </div>
        <div className="max-w-lg w-full animate-slow-bounce relative">
          <img className="w-full object-contain" src={box} alt="" />
        </div>
      </div>
  
      {/* <button
        onClick={connect}
        className="uppercase max-w-xs w-full font-DMSans md:hidden  rounded-md text-dark h-full text-xl font-bold flex items-center py-4 justify-center px-8 bg-yellow hover:bg-yellow-300 transition-colors relative"
      >
        <img className="absolute top-0 w-full object-contain left-0" src={btn_bg} alt="" />
        {connected ? <span>mint now</span> : <span> connect wallet</span>}
      </button> */}
    </div>
  </div>
  );
};
