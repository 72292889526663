import { useStateContext } from "../../context/State";
import { cn } from "../../lib/utils";
import { FarcanaSVG } from "../svg/FarcanaSVG";

export const LoadPage = () => {
  const { isLoading, progress, status } = useStateContext();

  return (
    <div
      className={cn(
        "min-h-[100dvh] fixed inset-0 z-50 bg-white flex flex-col w-full py-20 items-center justify-between gap-10 transition-all delay-[2000ms]",
        {
          ["opacity-100 pointer-events-auto delay-100"]: isLoading,
          ["opacity-0 pointer-events-none "]: !isLoading,
        }
      )}
    >
      <div className="flex flex-col gap-10 items-center">
        <FarcanaSVG color="#BBB8BF" />
        <div>
          <span className="text-muted font-bold font-DMSans uppercase">nft mint</span>
        </div>
      </div>

      <div>
        <span className="text-6xl xs:text-8xl uppercase text-dark select-none">
          {status === "await" ? (
            <span>Minting</span>
          ) : status === "success" ? (
            <span>success</span>
          ) : (
            <span>error</span>
          )}
          {status === "await" ? (
            <>
              <span className="animate-bounce inline-block">.</span>
              <span
                style={{
                  animationDelay: "100ms",
                }}
                className="animate-bounce inline-block"
              >
                .
              </span>
              <span
                style={{
                  animationDelay: "200ms",
                }}
                className="animate-bounce inline-block delay-200"
              >
                .
              </span>
            </>
          ) : null}
        </span>
      </div>

      <div>
        <span className="text-muted text-2xl">
          <span>{progress}</span>%
        </span>
      </div>
    </div>
  );
};
