import React from 'react';
import clip_2 from "../assets/clip_2.svg";
import youtube from "../assets/youtube.svg";
import insta from "../assets/insta.svg";
import discord from "../assets/discord.svg";
import btn_bg from "../assets/btn-bg.svg";
import { useStateContext } from "../context/State";

import { ConnectButton } from "@rainbow-me/rainbowkit";

const Footer = () => {
  const { connected, connect, mint, allowlist, currentView  } = useStateContext();

    return (
      <div className={`max-w-4xl  flex w-full relative ${(currentView === "error") && `hidden`}`}>
      <div className="absolute w-full flex">
        <div className="w-28 h-20 md:block hidden">
          <img className="w-full h-full object-cover" src={clip_2} alt="" />
        </div>
        <div className="w-full h-20 bg-white md:block hidden"></div>
        <div className="w-28 h-20 md:block hidden">
          <img className="w-full h-full -scale-x-100 object-cover" src={clip_2} alt="" />
        </div>
      </div>

      {currentView !== "success" && <div className="w-full h-fit flex md:flex-row flex-col items-center justify-center flex-wrap p-3 gap-5 relative">
        
        {!connected ? <ConnectButton.Custom>
                  {({
                    account,
                    chain,
                    openAccountModal,
                    openChainModal,
                    openConnectModal,
                    authenticationStatus,
                    mounted,
                  }) => {
                    // Note: If your app doesn't use authentication, you
                    // can remove all 'authenticationStatus' checks
                    const ready = mounted && authenticationStatus !== "loading";
                    const connected =
                      ready &&
                      account &&
                      chain &&
                      (!authenticationStatus ||
                        authenticationStatus === "authenticated");

                    return (
                      <div
                        {...(!ready && {
                          "aria-hidden": true,
                          style: {
                            opacity: 0,
                            pointerEvents: "none",
                            userSelect: "none",
                          },
                        })}
                        className='md:w-auto md:block w-full md:mx-0 mx-auto flex justify-center'
                      >
                        {(() => {
                          if (!connected) {
                            return (
                              <button
                              onClick={openConnectModal}
                              className="uppercase border border-yellow md:min-w-auto  min-w-[320px]  font-DMSans rounded-md text-dark h-full text-sm font-bold md:flex items-center py-3 justify-center px-8 bg-yellow hover:bg-yellow-300 transition-colors relative"
                              >
                              <img className="absolute top-0 w-full object-contain left-0" src={btn_bg} alt="" />
                                <span>Connect Wallet</span>
                            </button>

                            );
                          }

                          if (chain.unsupported) {
                            return (
                              <button
                                onClick={openChainModal}
                                className="connect-wallet__btn border title ml-auto  uppercase mr-5 lg:block  border-white px-10 text-2xl py-2 w-fit rounded-full hover:bg-white hover:text-black "
                              >
                                Wrong network
                              </button>
                            );
                          }

                          return (
                            <div style={{ display: "flex", gap: 12 }}>
                              {/*<button
                            onClick={openChainModal}
                            style={{ display: 'flex', alignItems: 'right' }}
                            className="connect-wallet__btn border title ml-auto  uppercase mr-5 lg:block hidden border-white px-10 text-xl py-2 w-fit rounded-full hover:bg-white hover:text-black "
                          >
                            {chain.hasIcon && (
                              <div
                                style={{
                                  background: chain.iconBackground,
                                  width: 12,
                                  height: 12,
                                  borderRadius: 999,
                                  overflow: 'hidden',
                                  marginRight: 4,
                                }}
                              >
                                {chain.iconUrl && (
                                  <img
                                    alt={chain.name ?? 'Chain icon'}
                                    src={chain.iconUrl}
                                    style={{ width: 12, height: 12 }}
                                  />
                                )}
                              </div>
                            )}
                            {chain.name}
                          </button>*/}

                              <button
                                onClick={openAccountModal}
                                className="connect-wallet__btn border title ml-auto  uppercase mr-5 lg:block  border-white px-10 text-2xl py-2 w-fit rounded-full hover:bg-white hover:text-black "
                              >
                                {account.displayName}
                                {account.displayBalance
                                  ? ` (${account.displayBalance})`
                                  : ""}
                              </button>
                            </div>
                          );
                        })()}
                      </div>
                    );
                  }}
                </ConnectButton.Custom> :
                <button
                // onClick={mint}
                
                className="uppercase font-DMSans rounded-md text-dark h-full text-xl font-bold flex items-center py-4 justify-center px-8 bg-yellow hover:bg-yellow-300 transition-colors relative"
              >
                <img className="absolute top-0 w-full object-contain left-0" src={btn_bg} alt="" />
                  {/* Mint Now */} Sold Out
              </button>}
        {connected && <button
          className="text-sm font-bold font-DMSans border border-dark bg-dark text-white hover:bg-dark/80 transition-colors py-3 px-5 uppercase rounded-md"
        >
          {allowlist == 1 ? "You are on neither allowlist!" : allowlist == 2 ? "You are on the FCFS list!" : allowlist == 3 ? "You are on the Guaranteed list!" : allowlist == 4 ? "You are on both allowlists!" : "ERROR!"}
        </button>}
        {/* <button className="py-3 px-5 bg-transparent font-DMSans font-bold text-sm border border-muted text-muted rounded-md uppercase hover:bg-gray-100 transition-colors">
          mint details
        </button> */}
             {/* <button className="py-3 max-w-xs md:w-fit w-full px-6 bg-transparent font-DMSans font-bold text-sm border border-muted text-dark rounded-md uppercase hover:bg-gray-100 transition-colors">
          mint details
        </button> */}
        <div className="flex items-center gap-3 md:hidden ">
          <a
            className="flex h-10 w-10 rounded-full items-center justify-center border border-muted hover:bg-gray-100 transition-colors"
            target="_blank"
            href="#"
          >
            <img className="object-contain w-3/5" src={youtube} alt="" />
          </a>
          <a
            className="flex h-10 w-10 rounded-full items-center justify-center border border-muted hover:bg-gray-100 transition-colors"
            target="_blank"
            href="#"
          >
            <img className="object-contain w-3/5" src={insta} alt="" />
          </a>
          <a
            className="flex h-10 w-10 rounded-full items-center justify-center border border-muted hover:bg-gray-100 transition-colors"
            target="_blank"
            href="#"
          >
            <img className="object-contain w-3/5" src={discord} alt="" />
          </a>
        </div>
      </div>}
    </div>
    )
};

export default Footer;
