import React from 'react';
import { MainPage } from './MainPage';
import {SuccessPage} from '../success-page/SuccessPage';
import {ErrorPage} from '../error-page/ErrorPage';
import { useStateContext } from '../../context/State';

const ContainerPage = () => {
    const { currentView } = useStateContext();

    return (
        <>
            {currentView === 'main' && <MainPage />}
            {currentView === 'success' && <SuccessPage />}
            {currentView === 'error' && <ErrorPage />}
        </>
    );
};

export default ContainerPage;
